// eslint-disable-next-line
import SniperLogo from "./../assets/SNIPER.png";
import React from 'react';
import { useState, useEffect} from 'react';

import { ethers } from "ethers";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const Exchange = ({ symbol, balance, insertToken, addr, account, routerAddr, recipent, nativeAddr, decimals }) => {
  const [message,setMessage] = useState('');
  const [value, setValue] = useState(50); // Initial value at 50%
  const [address, setAddress] = useState(addr);
  const [GWEI, setGWEI] = useState('24')
  const [buyAmount, setBuyAmount] = useState('');
  const [open, setOpen] = React.useState(false);

  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;
    setOpen(false);
  };


    
    const theme = createTheme({
      components: {
        MuiSnackbar: {
          variants: [
            {
              props: { variant: "trouble" },
              style: {
                "& .MuiSnackbarContent-root": {
                  background: "#fff",
                  border: "1px solid #333",
                  color: "#333",
    
                }
              }
            }
          ]
        }
      }
    });
    
    



 
  
  

  const NATIVE_COIN = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'

  const url = `https://dexscreener.com/ethereum/${addr}?embed=1&theme=dark&info=0`;

  useEffect(() => {
 
    insertToken(address); 
   // eslint-disable-next-line
  }, [address]);  


  const router = new ethers.Contract(
    routerAddr,
    [
      'function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)',
      'function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
      'function swapExactETHForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)',
      'function swapExactTokensForETH(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)'
    ],
    account
  );




  const XSwap = () => {
    setOpen(true);
    setMessage(`Selling 100% ${symbol}`)
    Swap('Direct');
  }
  const YSwap = () => {
    setOpen(true)
    setMessage(`Selling ${value}% ${symbol}`)
    Swap('Indirect');
  }

  const Swap = async (status) => {
    let gweiValue = ethers.utils.parseUnits(GWEI, 9)

    const SellApproval = new ethers.Contract(
      address,
      [
        'function approve(address spender, uint amount) public returns(bool)',
      ],
      account
    );


    const txApprove = await SellApproval.approve(
      routerAddr,
      '1000000000000000000000000000000000000000000000000000',
      {
        gasPrice: ethers.BigNumber.from(gweiValue).toHexString(),
        gasLimit: ethers.BigNumber.from(300000).toHexString(),
      }
    );
    const receipt2 = await txApprove.wait();
    console.log('Approved: Transaction receipt');
    console.log(receipt2.transactionHash);
    setOpen(true)
    setMessage(`Approved Successfully, ${receipt2.transactionHash} `)


    if (status === 'Direct') {
      const tx = await router.swapExactTokensForETH(
        balance.toString(),
        0,
        [address, NATIVE_COIN],
        recipent,
        Date.now() + 1000 * 60 * 10,
        {
          gasPrice: ethers.BigNumber.from(gweiValue).toHexString(),
          gasLimit: ethers.BigNumber.from(300000).toHexString(),
        }
      );
      const receipt = await tx.wait();
      console.log('Sell: Transaction receipt');
      console.log(receipt.transactionHash);
      setOpen(true)
      setMessage(`Sold Successfully, ${receipt.transactionHash} `)
      setAddress(address)
      insertToken(address)

    }
    else {
      let balanceX = balance / 100 * value;
      const tx = await router.swapExactTokensForETH(
        balanceX.toString(),
        0,
        [address, NATIVE_COIN],
        recipent,
        Date.now() + 1000 * 60 * 10,
        {
          gasPrice: ethers.BigNumber.from(gweiValue).toHexString(),
          gasLimit: ethers.BigNumber.from(300000).toHexString(),
        }
      );
      const receipt = await tx.wait();
      console.log('Sell: Transaction receipt');
      console.log(receipt.transactionHash);
      setOpen(true)
      setMessage(`Sold Successfully, ${receipt.transactionHash} `)
      setAddress(address)
      insertToken(address)
    }

  }

  const BuySwap = async () => {
    setOpen(true)
    setMessage(`Buying ETH ${buyAmount} ${symbol} TOKENS`)



    let gweiValue = ethers.utils.parseUnits(GWEI, 9);


 


    let buyAttack = ethers.utils.parseUnits(buyAmount, 18);
    const tx = await router.swapExactETHForTokens(
      0,
      [NATIVE_COIN, address],
      recipent,
      Date.now() + 1000 * 60 * 10,
      {
        value: buyAttack,
        gasPrice: ethers.BigNumber.from(gweiValue).toHexString(),
        gasLimit: ethers.BigNumber.from(300000).toHexString(),
      }
    );
    const receipt = await tx.wait();
    console.log('BUY: Transaction receipt');
    console.log(receipt.transactionHash);
    setOpen(true)
    setMessage(`Purchased Successfully, ${receipt.transactionHash} `)
    setAddress(address)
    insertToken(address)
  }


  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
  };


  const handleChangeToken = (address) => {
    setAddress(address)
    insertToken(address)
  }

  return (
    <>
      <div className="container">
        <img src={SniperLogo} alt="Sniper" /> <br />
        <br />

        <div className="form-check form-switch " style={{ float: "right" }}>
          <input className="form-check-input" type="checkbox" />
          <label className="form-check-label text-white" for="mySwitch">Snipe Notification</label>
        </div>

        <input type="text" placeholder="Put Address Here" onChange={(e) => handleChangeToken(e.target.value)} className="form-control myInput bg-dark text-white" style={{ height: "40px" }} />
        <br />
        <div className="paneltool" style={{ border: "1px solid #5a5a5a", padding: "10px", borderRadius: "10px" }}>
          <div className="row">
            <div className="col-sm-3">
              <div className="card activecard text-white">
                <div className="card-body">
                  <p>Token Symbol</p>
                  <h3>{symbol}</h3>
                </div>
              </div>
            </div>

            <div className="col-sm-9">
              <div className="card cardswallet text-white">
                <div className="card-body">
                  <p>Token Balance</p>
                  <h3>{balance / Number('1e'+decimals)} {symbol}</h3>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-sm-6">
              <div className="card activecard text-white">
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-6">
                      <p>Snipe Amount</p>
                      <input type="number" placeholder="Type Amount"
                        onChange={(e) => setBuyAmount(e.target.value)}
                        className="form-control text-white bg-dark myInput" style={{ height: "40px" }} />
                    </div>
                    <div className="col-sm-6">
                      <p>Sniper Mode</p>
                      <select className="form-control bg-dark text-white fw-bold">
                        <option>ETH</option>

                      </select>
                    </div>
                  </div>
                  <br />

                  <div className="row mt-6">
                    <div className="col-12">
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="100"
                        step="1"
                        value={value}
                        onChange={handleChange}

                      />
                    </div>


                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      0%
                    </div>

                    <div className="col-sm-4" style={{ textAlign: "center" }}>
                      50%
                    </div>

                    <div className="col-sm-4" style={{ textAlign: "right" }}>
                      100%
                    </div>
                  </div>

                </div>
              </div>

              <br />

              <div className="card activecard text-white">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-4">
                      <p className="fw-bold text-center">Slippage</p>
                      <input type="number" placeholder="Slippage" className="form-control bg-dark text-white text-center myInput" required/>
                    </div>
                    <div className="col-sm-4">
                      <p className="fw-bold text-center">Buy Gwei</p>
                      <input type="number" placeholder="Buy Gwei" onChange={(e) => setGWEI(e.target.value)} className="form-control bg-dark text-white text-center myInput" required/>

                    </div>
                    <div className="col-sm-4">
                      <p className="fw-bold text-center">Sell Gwei</p>
                      <input type="number" placeholder="Sell Gwei" onChange={(e) => setGWEI(e.target.value)} className="form-control bg-dark text-white text-center myInput" required/>
                    </div>
                  </div>
                  <br />
                  <p>Max Buy Tax</p>
                  <input type="number" placeholder="Sell Gwei" className="form-control bg-dark text-white  myInput" required/>
                  <br />
                  <p>Max Sell Tax</p>
                  <input type="number" placeholder="Sell Gwei" className="form-control bg-dark text-white  myInput" required/>
                </div>
              </div>
              <br />

              <div className="card activecard text-white">
                <div className="card-body">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4">
                        <button className="btn myBTN fw-bold btn-lg" style={{ width: "100%" }} onClick={XSwap}>SELL 100%</button>
                      </div>
                      <div className="col-sm-4" style={{ textAlign: "center" }}>
                        <button className="btn myBTN fw-bold btn-lg" style={{ width: "100%" }} onClick={YSwap}>SELL {value}%</button>
                      </div>

                      <div className="col-sm-4" style={{ textAlign: "right" }}>
                        <button className="btn myBTN fw-bold btn-lg" style={{ width: "100%" }} onClick={BuySwap}>BUY</button>
                      </div>
                    </div>
                  </div>
                </div></div>
            </div>

            <div className="col-sm-6">

              <div id="dexscreener-embed">
                { // eslint-disable-next-line  
                }   <iframe src={url}></iframe></div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        variant="trouble"
        open={open}
        autoHideDuration={5000}
        message={message}
        onClose={handleToClose}
        action={
          <React.Fragment>
            <IconButton
              size="large"
              aria-label="close"
              color="inherit"
              onClick={handleToClose}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </React.Fragment>
        }
        
      />
      </ThemeProvider>


    </>
  )
}

export default Exchange;
