import { Buffer } from 'buffer';
import Web3 from "web3";
import { ethers } from "ethers";


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from "./assets/logo.png"

import { useState } from 'react';
import Dashboard from './components/dashboard';
import Exchange from './components/exchange';
import Scanner from './components/scanner';
import Account from './components/accounts';

function App() {
  const [page,setPage] = useState(0);
  const [tokenO,setToken0] = useState('0x6982508145454Ce325dDbE47a25d4ec3d2311933')
  const [tokenBalance,setTokenBalance] = useState('0');
  const [tokenSymbol,setTokenSymbol] = useState('ETH');
  const [privateKey , setPrivateKey] = useState('');
  const [accountSignIN,setAccountSignIn] = useState('');
  const [userBalance,setUserBalance] = useState('');
  const [signer,setSigner] = useState('')
  const [decimals,setDecimals] = useState('18');
  let account;
  let CONFIGINFO;
  CONFIGINFO = {
      WBNB: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
      router: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
     liquadityTarget: '0',
      TAX: '10',
      approval: true,
      GWEI: '24', // new change
      // if you choice by supply then ignore the amount.
      amount: '0.005',
      supplyLimit: false,
      supplyPercentage: '1',
      maxLimit: '1',
      sellLimit: false,
      sellPercentage: '80', // Don't go more than 100 otherwise it will failed. 
      SERVER: 'wss://red-lingering-smoke.discover.quiknode.pro/9a5a25d11c3e5246d9d5701f1abe493eb3092e1a/',
      HTTP_SERVER: 'https://red-lingering-smoke.discover.quiknode.pro/9a5a25d11c3e5246d9d5701f1abe493eb3092e1a/',
      SIMULATION: '0x2f329764c4F6A58B751C451E25C0f8c7EF13e008',
      PROFIT_PERCENT: '2',
      STOP_LOSE_PERCENT: '10',
      SELLAUTO: true
    }

const ERC20ABI = [
  {
    "constant": true,
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "name": "",
        "type": "string"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },   
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },  
];



 
const Configuration = async () => {
  
if(privateKey !== '') {
const web3 = new Web3(new Web3.providers.HttpProvider(CONFIGINFO.HTTP_SERVER));

const secretKey = new Buffer.from(privateKey, "hex"); 

const provider = new ethers.providers.WebSocketProvider(CONFIGINFO.SERVER);
const wallet = new ethers.Wallet(secretKey);
account = wallet.connect(provider);
const userBalance = await web3.eth.getBalance(account.address);

setUserBalance(userBalance / 1e18)
setAccountSignIn(account.address)
setSigner(account);




const TokenInfo = async () => {
 
  const TokenDetail = new web3.eth.Contract(ERC20ABI, tokenO);
  let TokenSymbol = await TokenDetail.methods.symbol().call()
  let TokenBalance = await TokenDetail.methods.balanceOf(account.address).call()
  let TokenDecimal = await TokenDetail.methods.decimals().call()
  setTokenSymbol(TokenSymbol);
  setTokenBalance(TokenBalance);
  setDecimals(TokenDecimal);
}

TokenInfo()




}

}


const InsertPrivateKey = (privateKey) => {
  setPrivateKey(privateKey);
 

  Configuration();
}

const TokenAddress = async (insertToken) => {
  setToken0(insertToken);
  console.log(insertToken)
  Configuration()

}









  return (

  <>
  <div className='header'>
    <br />
    <div className='container'>
    <div className='row'>
      <div className='col-sm-6'>
        <img src={logo} alt="logo" style={{textAlign:"left"}} />
      </div>

      <div className='col-sm-6' style={{textAlign:"right"}}>
        <button className='btn myBTN fw-bold'>CONNECT WALLET</button>
      </div>
      </div>
    </div>
  </div>
  <hr style={{color:"#fff"}}/>
  <div className='menu' style={{marginTop:"-10px"}}>
    <div className='container'>
    <div className='row'>
      <div className='col-sm-6'>
        <ul className='menurow'>
          <li style={{color:"#fff"}} onClick={() => setPage(0)}> {page === 0? <><h5 className='apply'>Dashboard</h5></> : <><h5>Dashboard</h5></>}</li>
      {signer !== ''? <>
      <li style={{color:"#fff"}} onClick={() => setPage(1)}>{page === 1? <><h5 className='apply'>Sniper</h5></> : <><h5>Sniper</h5></>}</li>
          <li style={{color:"#fff"}} onClick={() => setPage(2)}>{page === 2? <><h5 className='apply'>Scanner</h5></> : <><h5>Scanner</h5></>}</li>
         
      </> : <></>}    <li style={{color:"#fff"}} onClick={() => setPage(3)}>{page === 3? <><h5 className='apply'>Accounts</h5></> : <><h5>Accounts</h5></>}</li>
        </ul>
      {/* <table className='table text-center text-white fw-bold'>
     
      <tr>
       
        {page === 1? <> <td  onClick={(e) => setPage(1)}>
        <h3 className='apply'>Sniper</h3>
        </td> </> : <> <td onClick={(e) => setPage(1)}>
          Sniper
        </td> </>}
        {page === 2? <> <td  onClick={(e) => setPage(2)}>
        <h3 className='apply'>Scanner</h3>
        </td></> : <> <td onClick={(e) => setPage(2)}>
          Scanner
        </td></>}
       {page === 3? <> <td  onClick={(e) => setPage(3)}>
       <h3 className='apply'>Accounts</h3>
        </td></> : <> <td onClick={(e) => setPage(3)}>
          Accounts
        </td></>}
       
      </tr>

    </table> */}
      </div>

      <div className='col-sm-6'>
       
      </div>
    </div>
   </div>
   <hr style={{width:"100%",height:"1px",background:"#fff",color:"#fff",marginTop:"-15px"}} />
   
  </div>
 <br />
 {page === 0? <>
<Dashboard />
 
 </> : 
 page === 1?
  <>
<Exchange symbol={tokenSymbol} balance={tokenBalance} insertToken={TokenAddress} addr={tokenO}
account={signer} routerAddr={CONFIGINFO.router} nativeAddr={CONFIGINFO.WBNB} recipent={accountSignIN}
decimals={decimals}
/>
 </> :
 
page === 2?
 <>
  <Scanner  
   account={signer}
   routerAddr={CONFIGINFO.router}
   nativeAddr={CONFIGINFO.WBNB}
   factoryAddr={CONFIGINFO.factory}
   recipent={accountSignIN}
   />
  </> 
 : 
 
 page === 3? 
 
 <>
<Account
 account={accountSignIN} 
signIn={InsertPrivateKey}
userBalance={userBalance}
/>
 
  </> 
 
 : <> NOT FOUND</>}
  

  </>
  );
}

export default App;
