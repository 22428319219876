import { useState } from "react";
import Scannerlogo from "./../assets/Scanner.png"
import { ethers } from "ethers";
const Scanner = ({account,routerAddr,nativeAddr,factoryAddr,recipent}) => {
  const [introMessage,setIntroMessage] = useState('')
  const [_token0,setToken0] = useState('');
  const [_token1,setToken1] = useState('');
  const [_pairAddress,setPairAddress] = useState('');
  const [GWEI,setGWEI] = useState('4');
  const [buyAmount,setBuyAmount] = useState('');
  const [address,setAddress] = useState('')
  const [found,setFound] = useState('')
  const [message,setMessage] = useState('')
  console.log(account)
  const factory = new ethers.Contract(
    factoryAddr,
    ['event PairCreated(address indexed token0, address indexed token1, address pair, uint)'],
    account
  );

  const router = new ethers.Contract(
    routerAddr,
    [
      'function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)',
      'function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)'
    ],
    account
  );

  const wbnb = new ethers.Contract(
    nativeAddr,
    [
      'function approve(address spender, uint amount) public returns(bool)',
    ],
    account
  );

  const BuySwap = async () => {
    



    let gweiValue = ethers.utils.parseUnits(GWEI, 9);

    const txApprove = await wbnb.approve(
      routerAddr,
      '1000000000000000000000000000000000000000000000000000',
      {
        gasPrice: ethers.BigNumber.from(gweiValue).toHexString(),
        gasLimit: ethers.BigNumber.from(3000000).toHexString(),
      }
    );
    const receipt2 = await txApprove.wait();
    console.log('Transaction receipt');
    console.log(receipt2.transactionHash);

    let buyAttack = ethers.utils.parseUnits(buyAmount, 18);
    const tx = await router.swapExactTokensForTokens(
      buyAttack,
      0,
      [nativeAddr, address],
      recipent,
      Date.now() + 1000 * 60 * 10,
      {
        gasPrice: ethers.BigNumber.from(gweiValue).toHexString(),
        gasLimit: ethers.BigNumber.from(3000000).toHexString(),
      }
    );
    const receipt = await tx.wait();
    console.log('BUY: Transaction receipt');
    setMessage(`Sniped Successfully ${receipt.transactionHash}`)
    console.log(receipt.transactionHash);

  }

  const handler = async (token0, token1, pairAddress) => {
      setToken0(`Token 0: ${token0}`)
      setToken1(`Token 1: ${token1}`)
      setPairAddress(`Pair Address: ${pairAddress}`)
     
      if(token0 === address) {
      setFound('Found Successfully Ready to Snipe')
      BuySwap()
      }
    
      if(token1 === address) {
      setFound('Found Successfully Ready to Snipe')
      BuySwap()
      }
    
      if(typeof tokenIn === 'undefined') {
        return;
      }
    };

const Start = async () => {
    setIntroMessage('Scanning Started.....')
    factory.on('PairCreated', handler);
  }

  const Stop = async () => {
    setIntroMessage('Scanning Stopped.....')
    factory.off('PairCreated', handler);
  }


    
    return (<>
    
    <div className="container">
        <img src={Scannerlogo} alt="scanner 0xsniper" /> <br />
  <br />
    <div className="row">
        <div className="col-sm-4">
      <select className="form-control bg-dark text-white  myInput">
        <option>Mempool</option>
      </select>
      </div>

      <div className="col-sm-8">
      <input type="text" placeholder="Put Address Here" onChange={(e) => setAddress(e.target.value) }  className="form-control bg-dark text-white myInput"  required/>
      </div>

      </div>
      <br />

      <div className="row" style={{border:"1px solid #5a5a5a",padding:"10px",borderRadius:"10px"}}>
        <div className="col-sm-6">
        <div className="card activecard text-white">
        <div className="card-body">
            <p>Amount</p>
           <input type="number" placeholder="Amount in ETH" onChange={(e) => setBuyAmount(e.target.value) }  className="form-control bg-dark text-white myInput" required/>
     <br />
      <div className="row" >
        <div className="col-sm-6">
            <label>GWEI</label>
            <input type="number" placeholder="GWEI" onChange={(e) => setGWEI(e.target.value) }  className="form-control bg-dark text-white  myInput" required />
        </div>
        <div className="col-sm-6">
            <label>TAX</label>
            <input type="number" placeholder="TAX" className="form-control bg-dark text-white  myInput" required />
        </div>
      </div>
            </div>
            </div>
    <br />
            <div className="card activecard text-white">
        <div className="card-body">
           <div className="row">
            <div className="col-sm-6">
            <button className="btn myBTN btn-lg" style={{width:"100%"}} onClick={Start}>START SCANNING</button>
            </div>

            <div className="col-sm-6">
            <button className="btn myBTN btn-lg" style={{width:"100%"}} onClick={Stop}>STOP SCANNING</button>
            </div>
           </div>
            </div>
            </div>
        </div>
        <div className="col-sm-6">
          <h3 className="fw-bold text-white text-center">{introMessage}</h3>
          <table className="table text-white">
            <thead>
              <tr>
                <td>Status</td>
           
              </tr>
            </thead>
            <tbody>
              <tr>
              
                <td className="badge">{_token0}</td>
        
              </tr>
              <tr>
              
                <td className="badge">{_token1}</td>
        
              </tr>
              <tr>
           
                <td className="badge">{_pairAddress}</td>
        
              </tr>
            </tbody>
          </table>
          <h3 className="text-white text-center fw-bold">{found}</h3>
          <h5 className="text-white text-center fw-bold">{message}</h5>
        </div>
      </div>


   
    </div>
    </>)
}

export default Scanner;
