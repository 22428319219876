import React, { useEffect, useState } from 'react';
import AccountsLogo from "./../assets/ACCOUNTS.png"

const Account = ({signIn,account,userBalance}) => {
  const [privateKey,setPrivateKey] = useState('');
  const [shortAddr,setShortAddr] = useState('')

  useEffect(() => {
    // This function will be called whenever `address` changes.
    // You can perform the desired action here, such as calling `insertToken`.
    SlicerAddr(account);
   
    handlePrivateKeyInsert(privateKey);
   // eslint-disable-next-line
  }, [privateKey,account]);

  const handlePrivateKeyInsert = () => {
    // Replace with your private key 

    signIn(privateKey);
   
  };

  const SlicerAddr = (account) => {
    const slicedAddress = account.slice(0, 6) + '...' + account.slice(-4);
    setShortAddr(slicedAddress);
  };

  const DisableAccount = () => {
    setPrivateKey('');
    signIn('')
    setShortAddr('')
  }





  

return (<>
 <div className="container">
    <img src={AccountsLogo} alt="Accounts 0xSniper" />
    <br />
  <br />

   <div className="row">
    <div className="col-sm-10">
    <input 
    type="password" 
    placeholder="Private Key" 
    className="form-control bg-dark text-white  myInput"
    onChange={(e) => setPrivateKey(e.target.value)}
   
   required
    
    />
    </div>
    <div className="col-sm-2">
        <button className="btn myBTN btn-lg" style={{width:"100%",height:"60px",borderRadius:"0px"}}  onClick={handlePrivateKeyInsert}>Add Address</button>
    </div>
   </div>


   <br />

   <div className="paneltool" style={{border:"1px solid #5a5a5a",padding:"10px",borderRadius:"10px"}}>
  <div className="container"> 
  <br />
   <p className="text-white">MY ADDED ACCOUNTS</p>
   <hr />
   <br />
{account!== ''? <>
<div className="input-group mb-3">
 <span className="btn btn-outline-warning">Balance: {Number(userBalance).toFixed(4)} ETH <br /> {shortAddr}</span>
  <input type="text" className="form-control bg-dark text-success" value="Enabled" disabled/>
  <button className="btn btn-outline-danger" type="button" onClick={DisableAccount}>Disable</button>
</div>
</> : <></>}
  


  
  </div>
   </div>
 </div>
 
 </>)
}

export default Account;
