import faIcon from "./../assets/@.png"
import telegram from "./../assets/tg.png"
import phone from "./../assets/phone.png";
import messanger from "./../assets/messanger.png";
import mail from "./../assets/mail.png"
import wallet from "./../assets/wallet.png"
import icon from "./../assets/icon.png"
import chart from "./../assets/chart.png"

const Dashboard = () => {
    return (
    <>
    <div className='slogan'>
    <div className='container'>
      <div className='row'>
        <div className='col-sm-6'>
          <br />
       <h1 className='text-white fw-bold'><span  className='herotext'>Invite & Claim</span> your <br /> Rewards</h1>
     </div>

        <div className='col-sm-6'>
          <div className='card cardslogan'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-sm-6'><h5 className='text-white fw-bold' style={{padding:"10px"}}>Invited Users</h5></div>
                <div className='col-sm-6' style={{textAlign:"right"}}><img src={faIcon} alt="@" width={50}  className='img-fluid'/></div>
              </div>
              <div className='container'>
              <h3 className='text-white fw-bold' >0</h3>
              <p className='text-white'>Channels</p>
              <div className='row'>
                <div className='col-sm-5'>
                  <table>
                    <tr style={{padding:"2px"}}>
                      <td className='badge bg-dark' style={{padding:"10px",borderRadius:"10px"}}><img src={telegram} alt="Telegram" /></td>
                      <td className='badge bg-dark' style={{padding:"10px",borderRadius:"10px"}}><img src={phone} alt="Telegram" /></td>
                      <td className='badge bg-dark' style={{padding:"10px",borderRadius:"10px"}}><img src={messanger} alt="Telegram" /></td>
                      <td className='badge bg-dark' style={{padding:"10px",borderRadius:"10px"}}><img src={mail} alt="Telegram" /></td>
                    </tr>
                  </table>
                </div>
                <div className='col-sm-7'>
                  <input type='text' placeholder='referral' value="https://0xsniper.app/?0x25215125" className='form-control' style={{width:"100%",height:"50px",background:"#1c1c24",color:"#fff"}} />
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
     <div className='row'>
      <div className='col-sm-6'>
          <div className='card cardswallet text-white'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-sm-6'>
              <h5 style={{padding:"10px"}}>Holdings</h5>
              </div>

              <div className='col-sm-6' style={{textAlign:"right"}}><img src={wallet} alt="@" width={50}  className='img-fluid'/></div>
              </div>

             <h3> <img src={icon} alt="0xsniper" /> 0 0XS </h3>
             <div className='panelbar'>
            <div className='container'>
             
             <div className='row'>
              <div className='col-sm-6'><h5>Token Holding Duaration</h5></div>
              <div className='col-sm-6' style={{textAlign:"right"}}><h5>6 Months</h5></div>
             </div>
            </div>
            </div>
          <br />
            <div className="box">
      <img className="img-fluid" alt="Group" src="https://c.animaapp.com/UOurV00v/img/group-1000002187.png" />
    </div>
          </div>
      </div>
      </div>

      <div className='col-sm-6'>
      <div className="card text-white cardswallet2">
        
      <div className="card-body">
        <div className='row'>
          <div className='col-sm-6'><h5 style={{padding:"5px"}}>Reward</h5></div>
          <div className='col-sm-6' style={{textAlign:"right"}}><button className='btn myBTN fw-bold'>CLAIM YOUR EARNED FEE</button></div>
          </div>
    <br />
            
       <div className='row'>
        <div className='col-sm-4'>
        <div className="card bgcg text-white">
          <div className="card-body">
         
              <p className="text-wrapper-2">Total Rewards</p>
              <h3>0 OXS</h3>
               </div>
               </div>
               </div>


               <div className='col-sm-4'>
        <div className="card bgcg text-white">
          <div className="card-body">
         
              <p className="text-wrapper-2">Unclaimed Rewards</p>
              <h3>0 OXS</h3>
               </div>
               </div>
               </div>


               <div className='col-sm-4'>
        <div className="card bgcg text-white">
          <div className="card-body">
         
              <p className="text-wrapper-2">Claimed Rewards</p>
              <h3>0 OXS</h3>
               </div>
               </div>
               </div>
               
               </div>
             <br />
              
          
          </div>
        

    </div>
  </div>
  </div>
  </div>

  <br />
<div className='container'>
  <div className='card bgcx text-white'>
    <div className='card-body'>
      <table >
        <tr >
          <td style={{padding:"5px"}}> <h5 >Track Your Own Buy & Sell</h5></td>
          <td >
          <select className='form-control bg-dark text-white'>
      <option>Last 30 DAYS</option>
      <option>Last 15 DAYS</option>
      <option>Last 7 DAYS</option>
      <option>Last 1 DAYS</option>
    </select>
          </td>
        </tr>
      </table>

      <br />

   <div className='row'>
   <div className='col-sm-2'>
        <div className="card cardswallet text-white text-center">
          <div className="card-body">
         
              <p className="text-wrapper-2">Earned</p>
              <h3>0 OXS</h3>
               </div>
               </div>
               </div>

               <div className='col-sm-2'>
        <div className="card activecard text-white text-center">
          <div className="card-body">
         
              <p className="text-wrapper-2">Sold</p>
              <h3>0 OXS</h3>
               </div>
               </div>
               </div>
               <div className='col-sm-8'> 
                <img src={chart} alt="chart" className='img-fluid' style={{height:"100px"}}/>
               </div>
   </div>
 
    </div>
  </div>
  </div>

<br />

  <div className='container'>
  <div className='card bgcx text-white'>
    <div className='card-body'>
      <div className='row'>
      <div className='col-sm-6'>
      <h5 className='fw-bold'>Fee Tracking</h5>
      <br />
  <div className='row'>
    <div className='col-sm-6'>
      <div className="card cardswallet text-white text-center">
          <div className="card-body">
         
              <p className="text-wrapper-2">Eligible Users</p>
              <h3>0</h3>
               </div>
               </div>
      </div>
   
      <div className='col-sm-6'> <p className='fw-bold'>Total Fees</p>
      <table className='table table-borderless'>
        <tbody>        <tr>
          <td className='badge bg-dark fw-bold text-white'><h3 style={{padding:"5px"}}>1</h3></td>
          <td className='badge bg-dark fw-bold text-white'><h3 style={{padding:"5px"}}>6</h3></td>
          <td className='badge bg-dark fw-bold text-white'><h3 style={{padding:"5px"}}>4</h3></td>
          <td className='badge bg-dark fw-bold text-white'><h3 style={{padding:"5px"}}>1</h3></td>
        </tr>
        </tbody>

      </table>
      </div>
      </div>
      <br />
      <p className='text-white'>Fees are distributed on every 1st for a month. </p> <br />
      <div className="card activecard text-white">
          <div className="card-body">
           <p className="text-wrapper-2 fw-bold">Fee Distribution Details</p>
               </div>
               </div>
      </div>

      <div className='col-sm-6'>
        <h5 className='text-white fw-bold'>Distribution History</h5>
        <br />
        <table className='table table-dark text-center'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Fees</th>
              <th>Earned</th>
            </tr>
          </thead>
    </table>
        
        </div>

    </div>
    
  </div>
  </div>
  </div>
  </div>
  </>
  )
}

export default Dashboard;
